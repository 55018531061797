'use client'
import Link from 'next/link'
import Image from 'next/image'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import {
  FOOTER_LINKS,
  SOCIALMEDIA_ICON_PROPS,
  SOCIALMEDIA_LINKS
} from '../utils/constants'
import { useMediaQuery } from '@mui/material'
import isMobile from "is-mobile";
import { WHATSAPP_NUMBER } from '../utils/constants'

const AppFooter = () => {
  const isTabletMinBreakpoint = useMediaQuery('(min-width:740px)')
  const isDesktopMinBreakpoint = useMediaQuery('(min-width:1440px)')

  const getWhatsappLink = isMobile() 
          ? `whatsapp://send?phone=${WHATSAPP_NUMBER}`
          : `https://wa.me/${WHATSAPP_NUMBER}`;

  return (
    <footer>
      <Container sx={{ p: 0, backgroundColor: '#2B0A08' }}>
        <Container sx={{ paddingBottom: 2, paddingX: 5, paddingTop: 5 }}>
          <Grid container sx={{ display: { xs: 'none', lg: 'flex' } }}>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={2}>
                  <Link href='/mission'>
                    <Typography
                      color='white'
                      fontWeight='fontWeightBold'
                      variant='body1'
                    >
                      About Us
                    </Typography>
                  </Link>
                </Grid>
                {Object.keys(FOOTER_LINKS).map(column => (
                  <Grid item xs={2} key={column}>
                    <Typography
                      variant='body1'
                      fontWeight='fontWeightBold'
                      color='white'
                    >
                      {column}
                    </Typography>
                    {FOOTER_LINKS[column].map(({ linkText, linkTo }) => (
                      <Link href={linkTo} key={linkText}>
                        <Typography
                          color='white'
                          variant='body2'
                          paddingTop={1}
                        >
                          {linkText}
                        </Typography>
                      </Link>
                    ))}
                  </Grid>
                ))}
                <Grid item xs={2}>
                  <Link href='/events'>
                    <Typography
                      color='white'
                      variant='body1'
                      fontWeight='fontWeightBold'
                    >
                      Events
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={2}>
                  <Link href='/donate'>
                    <Typography
                      color='white'
                      variant='body1'
                      fontWeight='fontWeightBold'
                    >
                      Donate
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              display='flex'
              justifyContent='space-between'
              flexDirection='column'
            >
              <Grid container>
                <Grid item xs={12} display='flex'>
                  <Typography
                    variant='body1'
                    marginBottom={2}
                    fontWeight={700}
                    color='white'
                  >
                    Connect with Us
                  </Typography>
                </Grid>
                <Grid container rowSpacing={2}>
                  {Object.keys(SOCIALMEDIA_LINKS).map(name => (
                    <Grid
                      item
                      xs={4}
                      display='flex'
                      key={name}
                      alignItems='center'
                    >
                      <Image
                        src={`/icon/${name}.svg`}
                        alt={`${name} logo`}
                        {...SOCIALMEDIA_LINKS[name].icon}
                      />
                      <Link
                        href={SOCIALMEDIA_LINKS[name].linkTo}
                        target='_blank'
                      >
                        <Typography
                          color='white'
                          variant='body1'
                          sx={{
                            lineHeight: 1.175,
                            paddingLeft: name === 'Facebook' ? 1 : 1.5
                          }}
                        >
                          {name}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {!isDesktopMinBreakpoint && (
                <>
                  <Grid container rowSpacing={2} marginTop={5} marginBottom={2}>
                    <Grid item display='flex'>
                      <img
                        src={'/Whatsapp.svg'}
                        width={SOCIALMEDIA_ICON_PROPS.sx.width}
                        height={SOCIALMEDIA_ICON_PROPS.sx.height}
                        style={{ marginRight: 8 }}
                        alt="Whatsapp icon"
                      />
                      <Link href={getWhatsappLink} target="_blank" aria-label="Message us at +91 6360752091">
                        <Typography variant='body2' color='white'>
                          +91 63607 52091
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12} display='flex'>
                      <LocalPhoneIcon {...SOCIALMEDIA_ICON_PROPS} />
                      <Typography variant='body2' color='white' aria-label="Call us at +91 8008533359">
                        +91 80085 33359
                      </Typography>
                    </Grid>
                    <Grid item xs={12} display='flex'>
                      <EmailIcon {...SOCIALMEDIA_ICON_PROPS} />
                      <Link href='mailto:info@WinVinayaFoundation.org' aria-label="Email us at info@WinVinayaFoundation.org">
                        <Typography variant='body2' color='white'>
                          info@WinVinayaFounadation.org
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </>
              )}

              {isDesktopMinBreakpoint && (
                <div>
                  <Grid container marginBottom={2}>
                    <Grid item display='flex'>
                      <img
                        src={'/Whatsapp.svg'}
                        width={SOCIALMEDIA_ICON_PROPS.sx.width}
                        height={SOCIALMEDIA_ICON_PROPS.sx.height}
                        style={{ marginRight: 8 }}
                        alt="Whatsapp icon"
                      />
                      <Link href={getWhatsappLink} target="_blank" aria-label="Message us at +91 6360752091">
                        <Typography variant='body2' color='white'>
                          +91 63607 52091
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item display='flex'>
                      <LocalPhoneIcon {...SOCIALMEDIA_ICON_PROPS} />
                      <Typography variant='body2' color='white' aria-label="Call us at +91 8008533359">
                        +91 80085 33359
                      </Typography>
                    </Grid>
                    <Divider
                      orientation='vertical'
                      sx={{ marginX: 2, borderBottomWidth: 24, height: 24 }}
                    />
                    <Grid item display='flex'>
                      <EmailIcon {...SOCIALMEDIA_ICON_PROPS} />
                      <Link href='mailto:info@WinVinayaFoundation.org' aria-label="Email us at info@WinVinayaFoundation.org">
                        <Typography variant='body2' color='white'>
                          info@WinVinayaFounadation.org
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>

          {!isTabletMinBreakpoint && (
            <Grid container rowSpacing={4}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Link href='/mission'>
                      <Typography
                        sx={{ lineHeight: 1.172 }}
                        color='white'
                        fontWeight='fontWeightBold'
                        variant='body1'
                      >
                        About Us
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      color='white'
                      sx={{ lineHeight: 1.172 }}
                      fontWeight='fontWeightBold'
                    >
                      WinVinaya Academy
                    </Typography>
                    {FOOTER_LINKS[`WinVinaya Academy`].map(
                      ({ linkText, linkTo }) => (
                        <Link href={linkTo} key={linkText}>
                          <Typography
                            sx={{
                              fontSize: '0.875rem',
                              paddingTop: 1.5,
                              lineHeight: 1.175
                            }}
                            color='white'
                          >
                            {linkText}
                          </Typography>
                        </Link>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      color='white'
                      fontWeight='fontWeightBold'
                      variant='body1'
                      sx={{ lineHeight: 1.172 }}
                    >
                      Achievements
                    </Typography>
                    {FOOTER_LINKS[`Achievements`].map(
                      ({ linkText, linkTo }) => (
                        <Link href={linkTo} key={linkText}>
                          <Typography
                            color='white'
                            sx={{
                              fontSize: '0.875rem',
                              paddingTop: 1.5,
                              lineHeight: 1.175
                            }}
                          >
                            {linkText}
                          </Typography>
                        </Link>
                      )
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      color='white'
                      fontWeight='fontWeightBold'
                      sx={{ lineHeight: 1.172 }}
                      variant='body1'
                    >
                      Engage with Us
                    </Typography>
                    {FOOTER_LINKS[`Engage with Us`].map(
                      ({ linkText, linkTo }) => (
                        <Link href={linkTo} key={linkText}>
                          <Typography
                            color='white'
                            sx={{
                              fontSize: '0.875rem',
                              paddingTop: 1.5,
                              lineHeight: 1.175
                            }}
                          >
                            {linkText}
                          </Typography>
                        </Link>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Link href='/events'>
                      <Typography
                        color='white'
                        variant='body1'
                        sx={{ lineHeight: 1.172 }}
                        fontWeight='fontWeightBold'
                      >
                        Events
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={6}>
                    <Link href='/donate'>
                      <Typography
                        sx={{ lineHeight: 1.172 }}
                        color='white'
                        variant='body1'
                        fontWeight='fontWeightBold'
                      >
                        Donate
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant='body1'
                  sx={{ lineHeight: 1.75 }}
                  paddingTop={1}
                  paddingBottom={2}
                  fontWeight={700}
                  color='white'
                >
                  Connect with Us
                </Typography>
                <Grid container rowSpacing={3}>
                  {Object.keys(SOCIALMEDIA_LINKS).map(name => (
                    <Grid
                      item
                      xs={4}
                      display='flex'
                      key={name}
                      alignItems='center'
                    >
                      <Image
                        src={`/icon/${name}.svg`}
                        alt={`${name} logo`}
                        {...SOCIALMEDIA_LINKS[name].icon}
                      />
                      <Link
                        href={SOCIALMEDIA_LINKS[name].linkTo}
                        target='_blank'
                      >
                        <Typography
                          color='white'
                          variant='body1'
                          sx={{
                            lineHeight: 1.175,
                            paddingLeft: name === 'Facebook' ? 1 : 1.5
                          }}
                        >
                          {name}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
                <Grid container marginTop={5.5} marginBottom={2}>
                  <Grid item display='flex'>
                    <img
                      src={'/Whatsapp.svg'}
                      width={SOCIALMEDIA_ICON_PROPS.sx.width}
                      height={SOCIALMEDIA_ICON_PROPS.sx.height}
                      style={{ marginRight: 8 }}
                      alt="Whatsapp icon"
                    />
                    <Link href={getWhatsappLink} target="_blank" aria-label="Message us at +91 6360752091">
                      <Typography variant='body2' color='white'>
                        +91 63607 52091
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
                <Grid container rowSpacing={2}>
                  <Grid item display='flex' xs={12}>
                    <LocalPhoneIcon {...SOCIALMEDIA_ICON_PROPS} />
                    <Link href="tel:+918008533359" aria-label="Call us at +91 8008533359">
                      <Typography
                        variant='body1'
                        color='white'
                        sx={{ lineHeight: 1.175 }}
                      >
                        +91 80085 33359
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item display='flex' xs={12}>
                    <EmailIcon {...SOCIALMEDIA_ICON_PROPS} />
                    <Link href='mailto:info@WinVinayaFoundation.org' aria-label="Email us at info@WinVinayaFoundation.org">
                      <Typography
                        variant='body1'
                        color='white'
                        sx={{ lineHeight: 1.175 }}
                      >
                        info@WinVinayaFounadation.org
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {isTabletMinBreakpoint && (
            <Grid
              container
              sx={{ display: { sm: 'flex', lg: 'none' } }}
              rowSpacing={4}
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Link href='/mission'>
                      <Typography
                        color='white'
                        fontWeight='fontWeightBold'
                        variant='body1'
                        sx={{ lineHeight: 1.172 }}
                      >
                        About Us
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      color='white'
                      sx={{ lineHeight: 1.172 }}
                      fontWeight='fontWeightBold'
                      variant='body1'
                    >
                      WinVinaya Academy
                    </Typography>
                    {FOOTER_LINKS[`WinVinaya Academy`].map(
                      ({ linkText, linkTo }) => (
                        <Link href={linkTo} key={linkText}>
                          <Typography
                            sx={{
                              fontSize: '0.875rem',
                              paddingTop: 1.5,
                              lineHeight: 1.175
                            }}
                            color='white'
                          >
                            {linkText}
                          </Typography>
                        </Link>
                      )
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      color='white'
                      sx={{ lineHeight: 1.172 }}
                      fontWeight='fontWeightBold'
                      variant='body1'
                    >
                      Achievements
                    </Typography>
                    {FOOTER_LINKS[`Achievements`].map(
                      ({ linkText, linkTo }) => (
                        <Link href={linkTo} key={linkText}>
                          <Typography
                            sx={{
                              fontSize: '0.875rem',
                              paddingTop: 1.5,
                              lineHeight: 1.175
                            }}
                            color='white'
                          >
                            {linkText}
                          </Typography>
                        </Link>
                      )
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      color='white'
                      sx={{ lineHeight: 1.172 }}
                      fontWeight='fontWeightBold'
                      variant='body1'
                    >
                      Engage with Us
                    </Typography>
                    {FOOTER_LINKS[`Engage with Us`].map(
                      ({ linkText, linkTo }) => (
                        <Link href={linkTo} key={linkText}>
                          <Typography
                            sx={{
                              fontSize: '0.875rem',
                              paddingTop: 1.5,
                              lineHeight: 1.175
                            }}
                            color='white'
                          >
                            {linkText}
                          </Typography>
                        </Link>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Link href='/events'>
                      <Typography
                        color='white'
                        variant='body1'
                        sx={{ lineHeight: 1.172 }}
                        fontWeight='fontWeightBold'
                      >
                        Events
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Link href='/donate'>
                      <Typography
                        color='white'
                        variant='body1'
                        sx={{ lineHeight: 1.172 }}
                        fontWeight='fontWeightBold'
                      >
                        Donate
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant='body1'
                  paddingBottom={2}
                  fontWeight={700}
                  color='white'
                >
                  Connect with Us
                </Typography>

                <Grid container>
                  {Object.keys(SOCIALMEDIA_LINKS).map(name => (
                    <Grid item xs display='flex' alignItems='center' key={name}>
                      <Image
                        src={`/icon/${name}.svg`}
                        alt={`${name} logo`}
                        {...SOCIALMEDIA_LINKS[name].icon}
                      />
                      <Link
                        href={SOCIALMEDIA_LINKS[name].linkTo}
                        target='_blank'
                      >
                        <Typography
                          color='white'
                          variant='body1'
                          sx={{
                            lineHeight: 1.175,
                            paddingLeft: name === 'Facebook' ? 1 : 1.5
                          }}
                        >
                          {name}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
                <Grid container marginTop={5.5}>
                  <Grid item display='flex' xs='auto'>
                    <img
                      src={'/Whatsapp.svg'}
                      width={SOCIALMEDIA_ICON_PROPS.sx.width}
                      height={SOCIALMEDIA_ICON_PROPS.sx.height}
                      style={{ marginRight: 8 }}
                      alt="Whatsapp icon"
                    />
                    <Link href={getWhatsappLink} target="_blank" aria-label="Message us at +91 6360752091">
                      <Typography variant='body2' color='white'>
                        +91 63607 52091
                      </Typography>
                    </Link>
                  </Grid>
                  <Divider
                    orientation='vertical'
                    sx={{ marginX: 2, borderBottomWidth: 24, height: 24 }}
                  />
                  <Grid item display='flex' xs='auto'>
                    <LocalPhoneIcon {...SOCIALMEDIA_ICON_PROPS} />
                    <Typography variant='body1' color='white' aria-label="Call us at +91 8008533359">
                      +91 80085 33359
                    </Typography>
                  </Grid>
                  <Divider
                    orientation='vertical'
                    sx={{ marginX: 2, borderBottomWidth: 24, height: 24 }}
                  />
                  <Grid item display='flex' xs='auto'>
                    <EmailIcon {...SOCIALMEDIA_ICON_PROPS} />
                    <Link href='mailto:info@WinVinayaFoundation.org' aria-label="Email us at info@WinVinayaFoundation.org">
                      <Typography variant='body1' color='white'>
                        info@WinVinayaFounadation.org
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
        <Typography
          sx={{ textAlign: { xs: 'left', lg: 'center' } }}
          variant='body1'
          bgcolor='black'
          color='white'
          paddingY={2}
          paddingX={5}
        >
          Copyright © 2022 WinVinaya Foundation | All Rights Reserved
        </Typography>
        {
          <Container
            sx={{ height: 56, display: { xs: 'flex', lg: 'none' } }}
          ></Container>
        }
      </Container>
    </footer>
  )
}

export default AppFooter
