'use client';
import { styled, Box } from "@mui/material";

const StyledSkipLink = styled('a')(() => ({
    display: 'flex',
    fontSize: '1rem',
    color: '#3A602A',
    padding: '0.5rem',
    position: 'absolute',
    top: '-3rem',
    transition: 'top .2s',
    zIndex: 10000,
    '&:focus-visible': {
        backgroundColor: '#FFFFFF',
        top: 0,
    },
}));

const SkipLink = () => {
    return (
        <Box role="region" aria-label="skip navigation">
            <StyledSkipLink href="#main" className="skip-link">Skip to main content</StyledSkipLink>
        </Box>
    );
};

export default SkipLink;