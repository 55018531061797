'use client'
import { useState, useRef, useContext } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { styled, useMediaQuery } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import MenuIcon from '@mui/icons-material/Menu'
import AppSearch from '../AppSearch'
import theme from '../ThemeRegistry/theme'
import dynamic from 'next/dynamic'
import FontSizeContext from '../ThemeRegistry/FontSizeContext'

const ActionContact = dynamic(() => import('./ActionContact'))
const DynamicDrawer = dynamic(() => import('./AppDrawer'))

const HeaderWrapper = styled(Box)(({ theme }) => ({
  padding: 0,
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

const LogoWrapper = styled(Box)({
  position: 'relative'
})

const ActionsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 0
})

const ActionMagnify = styled(Button)(({ theme }) => ({
  padding: '0.875rem',
  fontSize: '0.875rem',
  fontWeight: 700,
  lineHeight: 'normal',
  color: theme.palette.common.black,
  textDecoration: 'none'
}))

const DrawerToggleButton = styled('button')(() => ({
  backgroundColor: 'transparent',
  border: 'none',
}));

const fontLevels = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2]

const AppHeader = () => {
  const { setFontSize } = useContext(FontSizeContext);
  const currentLevel = useRef(7)
  const [open, setOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const isLargeBreakpointStart = useMediaQuery(theme.breakpoints.up('lg'))
  const drawerToggleRef = useRef()

  const handleDrawerToggle = () => {
    setOpen(prevState => !prevState);
    drawerToggleRef.current.blur()
  }

  const handleReduceFontLevel = () => {
    if(currentLevel.current === 0) return;

    setFontSize(16 * fontLevels[currentLevel.current - 1]);
    currentLevel.current -= 1;
  }

  const handleResetFontLevel = () => {
    if(currentLevel.current === 7) return;

    setFontSize(16 * fontLevels[7]);
    currentLevel.current = 7;
  }

  const handleIncreaseFontLevel = () => {
    if(currentLevel.current === 12) return;

    setFontSize(16 * fontLevels[currentLevel.current + 1]);
    currentLevel.current += 1;
  }

  return isLargeBreakpointStart ? (
    <AppBar position='static' sx={{ paddingY: 2, paddingX: 5 }}>
      <HeaderWrapper>
        <LogoWrapper width={280} height={55}>
          <Link href='/'>
            <Image src='/logo.png' alt='WinVinaya logo' tabIndex='0' fill loading='eager'/>
          </Link>
        </LogoWrapper>
        <ActionsWrapper>
          <AppSearch />
          <Box sx={{ flexGrow: 1, marginRight: 2 }}>
            <ActionContact />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              borderRadius: '4px',
              border: '1px solid #949494'
            }}
          >
            <ActionMagnify>-A</ActionMagnify>
            <ActionMagnify>A</ActionMagnify>
            <ActionMagnify>A+</ActionMagnify>
          </Box>
        </ActionsWrapper>
      </HeaderWrapper>
    </AppBar>
  ) : (
    <AppBar
      position='static'
      sx={{ paddingY: 3, paddingX: { xs: 3, sm: 5 } }}
    >

      <HeaderWrapper>
        <DrawerToggleButton
          onClick={handleDrawerToggle}
          onKeyDown={(e) => {
            if(e.key === ' ') handleDrawerToggle();
          }}
          aria-label="Open Main Menu"
          aria-hidden={false}
          aria-expanded={open}
          aria-controls="drawer"
          ref={drawerToggleRef}
        >
          <MenuIcon color='primary' />
        </DrawerToggleButton>
        {!isSearchOpen ? <LogoWrapper width={200} height={40}>
          <Link href='/'>
            <Image src='/logo.png' alt='WinVinaya logo' tabIndex='0' fill />
          </Link>
        </LogoWrapper> : null}
        {isSearchOpen ? <AppSearch onCancel={() => setIsSearchOpen(false)}/> : <SearchIcon sx={{ display: 'flex', fontSize: 24 }} onClick={() => setIsSearchOpen(true)} />}
      </HeaderWrapper>
      <DynamicDrawer open={open} handleDrawerToggle={handleDrawerToggle} />
    </AppBar>
  )
}

export default AppHeader
