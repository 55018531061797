'use client'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const BANNER_IMG_PATH_PARTIAL = '/banner'
const NOBANNER_ROUTES = []
const HERO_DATA = {
    blog: {
        title: 'Blog',
        alt: 'A group of people in a collaborative workspace'
    },
    careers: {
        title: 'Careers',
        alt: 'Group of people posing for a photo'
    },
    currentwork: {
        title: 'What We Do',
        alt: 'a group of people holding certificates'
    },
    contact: {
        title: 'Contact Us',
        alt: 'Group of people posing for a photo'
    },
    corpEngagement: {
        title: 'Corporate Engagement',
        alt: 'A group of people engaged in a discussion in an office'
    },
    events: {
        title: 'Events',
        alt: 'A diverse group of people smiling and posing for a photo'
    },
    internship: {
        title: 'Internship',
        alt: 'People collaborating on a project',
    },
    learn: {
        title: 'Learning Sign language',
        alt: 'A group of students learning on a laptop'
    },
    'about-us': {
        title: 'About Us',
        alt: 'An orator on the podium'
    },
    newsletter: {
        title: 'Newsletter',
        alt: 'A group of volunteers discussing'
    },
    program: {
        title: 'Our Program',
        alt: 'People lecturing on podium'
    },
    donate: {
        title: 'Donate',
        alt: 'A group of people discussing in office'
    },
    performance: {
        title: 'Performance Report',
        alt: 'Students discussing in classroom', src: `${BANNER_IMG_PATH_PARTIAL}/stories.svg`,
    },
    testimonials: {
        title: 'Testimonial',
        alt: 'A person teaching in a classroom'
    },
    search: {
        title: 'Search Results',
        alt: 'A diverse group of people smiling and posing for a photo'
    },
    sensitization: {
        title: 'Get Sensitized',
        alt: 'A group of people in a seminar'
    },
    services: {
        title: 'DE&I Services',
        alt: 'People enjoying a session'
    },
    stories: {
        title: 'Success Stories',
        alt: 'Students discussing in classroom'
    },
    volunteer: {
        title: 'Volunteer',
        alt: 'A group of people working on a project',
        signup: { alt: '', title: 'Volunteer Form' }
    }
}

const StyledTitle = styled('h1')(({ theme }) => ({
    position: 'absolute',
    left: 40,
    color: theme.palette.common.white,
    bottom: 16,
    margin: 0,
    fontSize: '3rem',
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
        bottom: '35%',
        fontSize: '1.5rem'
    }
}))

const StyledHeroWrapper = styled(Box)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    color: theme.palette.common.white,
    height: 180
}))

const StyledGradientWrapper = styled(Box)({
    position: 'absolute',
    background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.3) 4.34%, rgba(0, 0, 0, 0.087) 95.52%)',
    width: '100%',
    height: '100%'
})

const StyledTitleGradientWrapper = styled(Box)({
    position: 'absolute',
    background: 'linear-gradient(85.81deg, rgba(0, 0, 0, 0.66) 3.28%, rgba(0, 0, 0, 0) 87.83%)',
    width: '40%',
    height: '100%'
})

const StaticHero = ({ pathname }) => {

    const route = pathname.split('/')[0]
    const nestedRoute = pathname.split('/')[1]

    const component = HERO_DATA[route] ?? {
        src: `${BANNER_IMG_PATH_PARTIAL}/sensitization.svg`,
        title: 'Winvinaya',
        alt: ''
    }

    const imageSrc = component.src
        ? component.src
        : `${BANNER_IMG_PATH_PARTIAL}/${route}.svg`
    const { title = '', ...componentProps } = component[nestedRoute] ? component[nestedRoute] : component
    return (
        <>
            <StyledHeroWrapper>
                <Image
                    src={imageSrc}
                    {...componentProps}
                    fill
                    style={{
                        objectFit: 'cover',
                    }}
                    priority />
                <StyledGradientWrapper />
                <StyledTitleGradientWrapper />
            </StyledHeroWrapper>
            <Typography 
                variant="h1" 
                aria-label={title}
                mt={5}
                ml={5}
            >{title}</Typography>
        </>
    )
}

const AppHero = () => {
    const pathname = usePathname().slice(1)

    return pathname == '' ? (
        <></>
    ) : NOBANNER_ROUTES.some(route => pathname.split('/')[0] === route) ? (
        <></>
    ) : <StaticHero pathname={pathname} />

}

export default AppHero
