'use client';
import { useState } from 'react';
import { useSearchParams } from 'next/navigation';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { styled, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRouter } from 'next/navigation';
import theme from '@/src/components/ThemeRegistry/theme';

const SearchWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 0.625rem',
  borderRadius: '4px',
  border: '1px solid #949494',
  backgroundColor: theme.palette.common.white,
  marginRight: theme.spacing(3),
  marginLeft: 0,
  [theme.breakpoints.down('lg')]: {
    marginRight: 0,
    marginLeft: theme.spacing(3),
  }
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  flexGrow: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const SearchLabel = styled('label')(() => ({
  position: 'absolute',
  top: "-999px",
}));

const AppSearch = ({ onCancel = null }) => {
  const isLargeBreakpointStart = useMediaQuery(theme.breakpoints.up('lg'))
  const searchParams = useSearchParams();
  const term = searchParams.get('term');
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState(term);

  const handleSearch = async (e) => {
    if(e.key !== 'Enter') {
      return;
    }

    router.push(`/search?term=${searchTerm}`);
  };

  return (
    <SearchWrapper>
      <SearchLabel for="search" tabIndex={0} aria-label="Search">Search</SearchLabel>
      <SearchIcon color='primary' sx={{ fontSize: 24 }} />
      <StyledInputBase
        id="search"
        placeholder="Search WinVinaya Foundation"
        inputProps={{ 'aria-label': 'search' }}
        endAdornment={searchTerm || !isLargeBreakpointStart ?
          <InputAdornment
            position="end"
            onClick={(e) => {
              setSearchTerm('');
              if(onCancel) {
                onCancel();
              }
            }}
            sx={{ cursor: 'pointer' }}
          >
            <CloseIcon color="primary"  sx={{ fontSize: 24 }}/>
          </InputAdornment> : null}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        onKeyDown={handleSearch}
      />
    </SearchWrapper>
  );
};

export default AppSearch;