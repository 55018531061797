'use client'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import BackHandIcon from '@mui/icons-material/BackHand'
import ChevronRight from '@mui/icons-material/ChevronRight'
import HomeIcon from '@mui/icons-material/Home'
import FavoriteIcon from '@mui/icons-material/Favorite'
import Link from 'next/link'
import { NAVLINKS, NEW_REGISTRATION } from '../utils/constants'

const STICKY_NAVBAR_LINKS = [
  { label: 'Home', icon: <HomeIcon />, href: '/' },
  {
    label: 'Register',
    icon: <AppRegistrationIcon />,
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSf296ZTSdkxt3mHk3WFuoYRaXFYWMMflIoHIiWuiE_n6tKZaw/viewform'
  },
  {
    label: 'Volunteer',
    icon: <BackHandIcon />,
    href: '/volunteer'
  },
  {
    label: 'Donate',
    icon: <FavoriteIcon />,
    href: '/donate'
  }
]

const StyledNavLink = styled(Link)(({ theme, currpath }) => ({
  display: 'inline-flex',
  textDecoration: 'none',
  color: theme.palette.common.white,
  fontSize: '1rem',
  margin: '0 2rem 0 0',
  borderBottom: currpath ? `2px solid ${theme.palette.common.white}` : 'none',
  [theme.breakpoints.down('md')]: { color: theme.palette.common.black },
  '&:hover': {
    borderBottom: `2px solid ${theme.palette.common.white}`
  },
  ['@media (min-width:1440px)']: {
    margin: '0 2.5rem 0 0'
  }
}))

const StyledNavLinkButton = styled(Button)(({ theme, currpath }) => ({
  color: theme.palette.common.white,
  borderBottom: currpath ? `2px solid ${theme.palette.common.white}` : 'none',
  padding: '0 0 0.75rem 0',
  margin: '0 1rem 0',
  fontWeight: 400,
  lineHeight: 'normal',
  borderRadius: 0,
  endIcon: {
    marginLeft: '4px'
  },
  [theme.breakpoints.down('md')]: { color: theme.palette.common.black },
  '&:hover': {
    borderBottom: `2px solid ${theme.palette.common.white}`
  },
  ['@media (min-width:1440px)']: {
    margin: '0 2.5rem 0 0'
  }
}))

const StyledNavLinkSecondary = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '0.875rem',
  textDecoration: 'none',
  padding: '0.875rem 1rem',
  width: '100%'
}))

const StyledStickyNavbarWrapper = styled(Paper)(({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 5,
  boxShadow: '0px -4px 25px 0px rgba(0, 0, 0, 0.12)'
}))


const NestedNavItem = ({ item }) => {

  const pathname = usePathname()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNavigation = event => {
    handleClose()
    const routerLink = event.currentTarget.getAttribute('data-routerlink')
    if (event.key == 'Enter') {
      window.location.href = routerLink
    }
  }

  const submenuLinks = item.submenu.links.map(navLink => navLink.href)
  return <>
    <StyledNavLinkButton
      key={item.label}
      onClick={event => setAnchorEl(event.currentTarget)}
      endIcon={<ChevronRight sx={{ transform: 'rotate(90deg)' }} />}
      aria-controls={anchorEl ? item.submenu.id : undefined}
      aria-haspopup='true'
      aria-expanded={anchorEl ? 'true' : undefined}
      aria-label={`${item.label} Contains ${submenuLinks.length} Sub-Menu Items`}
      role='link'

      currpath={submenuLinks.includes(pathname)}
    >
      {item.label}
    </StyledNavLinkButton>
    <Menu
      id={item.submenu.id}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      slotProps={{ paper: { sx: { minWidth: 150 } } }}
      anchorOrigin={{
        vertical: 'bottom'
      }}
      transformOrigin={{ vertical: -15 }}
    >
      {item.submenu.links.map(({ label, href }) => (
        <MenuItem
          key={label}
          data-routerlink={href}
          onClick={handleNavigation}
          tabIndex={0}
          disableGutters
          sx={{ padding: 0 }}
        >
          <StyledNavLinkSecondary href={href} role='link'>
            {label}
          </StyledNavLinkSecondary>
        </MenuItem>
      ))}
    </Menu>
  </>
}

const NavItem = ({ item }) => {
  const pathname = usePathname()
  return item.submenu ? (<NestedNavItem item={item} />) : (
    <StyledNavLink
      key={item.label}
      href={item.href}
      aria-label={item.label}
      role='link'
      currpath={pathname === item.href}
    >
      {item.label}
    </StyledNavLink>
  )
}

function AppNavbar() {
  return (
    <AppBar position='static' component='nav' sx={{ paddingX: 5 }}>
      <Toolbar disableGutters>
        <Box
          sx={{ flexGrow: 1, display: 'flex' }}
          tabIndex={0}
          aria-label={`Menu with ${NAVLINKS.length} list Items`}
        >
          {NAVLINKS.map(navLink => (
            <NavItem key={navLink.label} item={navLink} />
          ))}
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Button
            variant='register'
            href={NEW_REGISTRATION}
            aria-label='New Registration'
            role='button'
            target='_blank'
          >
            New Registration
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default AppNavbar

const APPSTICKY_NAVBAR_ROUTES = STICKY_NAVBAR_LINKS.map(navLink => navLink.href)

export const AppStickyNavbar = () => {
  const pathname = usePathname()
  const router = useRouter()

  const [route, setRoute] = useState(pathname)

  useEffect(() => {
    setRoute(APPSTICKY_NAVBAR_ROUTES.includes(pathname) ? pathname : '')
  }, [pathname])

  const handleNavigation = routerLink => {
    setRoute(routerLink)
    router.push(routerLink)
  }

  return (
    <StyledStickyNavbarWrapper>
      <BottomNavigation
        showLabels
        value={route}
        sx={{ justifyContent: 'space-between' }}
      >
        {STICKY_NAVBAR_LINKS.map(({ label, icon, href }) => (
          <BottomNavigationAction
            value={href}
            label={label}
            icon={icon}
            key={label}
            tabIndex={0}
            onClick={() => handleNavigation(href)}
          />
        ))}
      </BottomNavigation>
    </StyledStickyNavbarWrapper>
  )
}
