'use client';
import { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import NextAppDirEmotionCacheProvider from './EmotionCache';
import FontSizeContext from '@/src/components/ThemeRegistry/FontSizeContext';


export default function ThemeRegistry({ children }) {
  const [fontSize, setFontSize] = useState(16);

  const updatedTheme = createTheme({
    ...theme,
    typography: {
      ...theme.typography,
      fontSize,
    },
  });

  return (
    <NextAppDirEmotionCacheProvider options={{ key: 'mui', prepend: true }}>
      <ThemeProvider theme={theme}>
        <FontSizeContext.Provider value={{ fontSize, setFontSize }}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {children}
        </FontSizeContext.Provider>
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}
